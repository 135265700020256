.Article{
    .block {
        &.block:first-of-type {
            margin-top: 2em !important;
            padding: 1.8em !important;
        }
        i.icon{
            display: block !important;
            margin: 0.2em auto !important;
            font-size: 2.6em !important;
        }
        .header{
            margin-top: 1em !important;
        }
        .list{
            margin-left: 4rem !important
        }
        .text{
            padding-top: 8em;
            padding-left: 8em;
        }
    }
}