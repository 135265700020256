$color-grey : #828282;
.Finder{
    h3{
        margin-top: 0.5em !important
    }
    .circular{
        margin-right: 0 !important
    }
    .search-container{
        margin-top: 4em;
      
        .search {
            i.search{
                color: $color-grey;
            };
            .ui.input>input:focus{
               border-color: $color-grey !important
            }
            .input{
                display: flex;
                margin: 0 2em;
                &:focus{
                    border-color:  $color-grey !important
                }
            }
            .results{
                width: 100% !important
            }
        }
        .segment {
            padding: 3em 1em;
            @media only screen and (max-device-width : 640px) {
                padding: 1.5em 1em;
            }
    }
    }
}

.accordion{
    .item{
        .header{
            font-weight: 300 !important
        }
    }
}