.Helper{
    height: 10em;
    padding: 4em 1em;
    background-color: white;
    margin-top: 0.5em;
    $color-green: rgba(131,210,17,1);
    $color-yellow: rgba(255,208,40,1);
    $color-orange: rgba(253,113,19,1);
    $color-red: rgba(188,27,27,1);
    $pos-0: 0%;
    $pos-18: 18%;
    $pos-33: 33%;
    $pos-38: 38%;
    $pos-58: 58%;
    $pos-66: 66%;
    $pos-78: 78%;
    $pos-100: 100%;

    .bg-green{
        background-color: $color-green
    }
    .color-green{
        color: $color-green
    }
    .bg-yellow{
        background-color: $color-yellow
    }
    .color-yellow{
        color: $color-yellow
    }
    .bg-orange{
        background-color: $color-orange
    }
    .color-orange{
        color: $color-orange
    }
    .bg-red{
        background-color: $color-red
    }
    .color-red{
        color: $color-red
    }
    .pos-18 {
        left: $pos-18;
    }
    .pos-38 {
        left: $pos-38;
    }
    .pos-58{
        left: $pos-58;
    }
    .pos-78 {
        left: $pos-78;
    }

    .dots{
        position: relative;
        .info{
            position: absolute;
            font-size: 1.1em;
            @media only screen and (max-device-width : 640px) {
                font-size: 1em;
            }
            width: 10em;
            text-align: center;
        }
        .info-top{
            top: -3em;
            margin-left: -4.2em
        }
        .info-bottom{
            top: 2.5em;
            margin-left: -4.2em
        }
        .dot{
            height: 2em;
            width: 2em;
            border-radius: 50%;
            margin-top: -0.5em;
            box-shadow: 0px 0px 5px 0px rgba(0,0,0,0.50);
            display: inline-block;
            border: 3px solid white;
            position: absolute;
        }
    }
    .bar{
        height: 0.9em;
        border-radius: 4px;
        background: linear-gradient(90deg, $color-green $pos-0, $color-yellow $pos-33, $color-orange $pos-66,  $color-red $pos-100);
    }
}