#root{
    height: 100%;
}

.Company{
    height: 70% !important;
    padding-top: 0;
    .embed{
        padding-bottom: 0 !important;
    }
    .row{
        margin: 0 !important;
        padding-top: 0 !important;

    }
    .ui.embed {
        height: 100%;
        z-index: 5 !important;

    }
  
}
.ui.segments > .horizontal.segments{
    background-color: white !important;
}