.Footer{
    background-color: white !important;
    a{
        position: relative;
    }
    i{
        color: #9D9D9D !important;
        cursor: pointer;
        &:hover{
            color:  #499cd3 !important;
        }
    }
}