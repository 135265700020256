.Ccn{
    h1{
        margin: 1em 0 0 0 !important
    }
    h2{
        margin: 0.2em !important
    }
    h3{
        background-color: #adadad !important;
        color: white !important;
        display: inline-block !important;
        padding: 0.2em 1em !important;
        margin: 1em  0 0 0 !important;
        border-radius: 2em;
    }
    .link{
        .circle{
            margin-right: 0.5em
        }
        &[data-condition='active'] {
            pointer-events: none;
            background-color:#f1f1f1;
            .right::before{
                content: "";
            }
        }
    }
}   