.rsc-custom-os-option-element{
    outline : 0;

}
.result{
    .list {
        .header{
            color: #235677 !important;
            font-size: 1.2em;
            margin-bottom: 0.5em !important;
        }
        .item{
            padding: 1em 0 !important
        }   
    }
}
