.Widget-linear-percent{
    .ui.tiny.image{
        padding: 0 0.5em 0.5em 0.5em !important;
    }
    .progress{
            border-radius: 0 !important;
            margin: 0 !important;
        .bar{
            border-radius: 0 !important
        }
    }
}