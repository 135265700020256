.ChatBot{
    .reset {
        button {
            margin-top: 1em !important;
        }
    }
    .rsc{
        -webkit-overflow-scrolling: touch !important;
    }
    .rsc-content{
        padding-bottom: 20px
    }
    .rsc-container{
        border-radius: 0.4em;
        box-shadow: 0 1px 2px 0 #CCCCCC;
        height: 480px !important;
    }

    .rsc-cs{
        background: none !important;
        box-shadow: none !important;
        padding: 0.5em 0 !important;
        justify-content: start !important;
    }

    .rsc-ts-image, .rsc-custom-ts-image{
        height: 50px !important;
    }
    .rsc-custom-ts-image-container{
        display: inline-block;
        padding: 6px;
    }

    .rsc-custom-ts-image{
        display: inline-block !important;
        box-shadow: rgba(0,0,0,0.15) 0px 1px 2px 0px !important;
        border-radius: 50% 50% 0 50%;
        padding: 3px  !important;
    }

    .rsc-custom-ts-bubble{
        box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
        display: inline-block;
        background: rgb(255, 255, 255);
        border-radius: 18px 18px 18px 0px;
        margin: 0px 0px 10px;
        overflow: hidden;
        padding: 12px;
    }
    .rsc-custom-os-option{
        display: inline-block;
        padding: 2px;
    }
    .rsc-custom-os-option-element {
        box-shadow:  none !important;
        border: none !important;
        border-color: #235677 !important;
        color: rgb(255, 255, 255)  !important;
        display: inline-block !important;
        font-size: 14px !important;
        background: #235677 !important;
        border-radius: 12px !important;
        padding: 10px !important;
     
    }
    .rsc-custom-os-option-element.redo{
        border: 1px solid #E0E1E2 !important;
        :before {
            margin-right: 0.2em;
        }
    }
    .rsc-custom-ts-user{
        align-items: flex-end !important;
        display: flex  !important;
        -webkit-box-pack: end  !important;
        justify-content: flex-end  !important;
      
        .rsc-custom-ts-image{
            box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
            height: 40px;
            min-width: 40px;
            transform: scale(0);
            transform-origin: left bottom;
            animation: 0.3s ease 0s 1 normal forwards running Lmuha;
            border-radius: 50% 50% 50% 0px;
            padding: 3px;
        }
     
    }
}

.rsc-ts-bubble, .rsc-custom-ts-bubble{
    box-shadow: rgba(0, 0, 0, 0.15) 0px 1px 2px 0px;
    color: rgb(74, 74, 74) !important;
    display: inline-block;
    font-size: 14px !important;
    max-width: 50%;
    position: relative;
    transform: scale(0);
    transform-origin: right bottom;
    animation: 0.3s ease 0s 1 normal forwards running Lmuha;
    background: #ededed !important;
    border-radius: 18px 18px 18px 0px;
    margin: 0px 0px 10px !important;
    overflow: hidden;
    padding: 12px;
 }

.rsc-ts-user, .rsc-custom-ts-user{
    .rsc-ts-bubble, .rsc-custom-ts-bubble{
        border-radius: 18px 18px 0px;
     }
}


