.Header.menu{
    background-color: white !important;
    i.chevron{
        color: #9D9D9D !important;
    }
    .menu-right{
        justify-content: flex-end !important;
        i{
            margin-right: 1em !important;
        };
        .dropdown{
            padding-right: 1.5em !important;
        }
    }
    
    .menu-left{
        justify-content: flex-start !important;
        i{
            padding-left: 0.5em !important;
            cursor: pointer;
            
        }
    }
}