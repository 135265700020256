.Widget-block-nested{
    padding: 1.5em 1em 2.8em 1em !important;
    margin-bottom: 2em !important;
    h4.title{
        text-transform: uppercase !important;
        font-weight: 700 !important;
    }
    h4.center{
        margin: 0 !important
    }
    .divider{
        clear: both
    }
    .button{
        background-color: transparent !important;
        margin: -0.3em 0 0 0 !important;    
        padding: 0 0 0 0 !important;
        i{
            background-color: #ff4757 !important;
            opacity: 1 !important;
            color: white !important; 
        } 
        float: right !important;
    }
}