.Ccn-detail{
    .ui.button.custom{
        margin: 0 0.3em;
        background-color: #235677 !important;
        color: white !important;
        opacity: 0.7 !important;
        box-shadow: 0 0 5px rgba(0,0,0,0.3) inset !important;
        &.active{
            background-color: white !important;
            color: #4A4A4A !important;
            box-shadow: 0 1px 2px 0 #CCCCCC;
            opacity: 1 !important;
        }
    }
}