.Widget-list {
    img.tiny{
        width: 5rem !important;
        height: auto !important;
    }
    .item{
        padding: 0.8em 0 !important;
        @media only screen and (max-device-width : 375px) {
            text-align: center  !important;
            .content{
                display: block !important;
                padding: 0 !important
            }
        }
        
        .content{
            margin-left: 0.8em
        }
    }
    img{
        border-radius: initial !important;
    }
}
